.App {
    height: 100%;
    background-color: black;
    position: relative;
    z-index: 1;
}

.App__video {
    height: 100%;
    width: 100%;
}

.App__video-container {
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 2;
    position: absolute;
}
