.Ar {
    height: 100vh;
    width: 100vw;
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
}

.Ar__input {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 4;
    font-size: 20px;
    border-radius: 0;
}
