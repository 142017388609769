.Start {
    position: fixed;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.Start__button {
    color: #eee;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid #eee;
    border-radius: 0;
    height: auto;
    padding: 10px 30px;
    font-size: 32px;
}

.Start__button:hover {
    background-color: rgba(240, 240, 240, .2);
}
